/*
    Add settings
*/

frappe.provide("ads.settings");

$.extend(ads.settings, {

    get_ad_settings: function(){
        if(cint(frappe.boot.ad_settings.enabled) == 1){
            return frappe.boot.ad_settings.reference_name?frappe.boot.ad_settings.reference_name: frappe.boot.ad_settings.name;
        }
        return null;
    },

    get_google_analytical_info: function(as_args=false){
        let url = "";
        let args = {};
        try{
            if(clientId && sessionId){
                args = {
                    clientId: clientId,
                    sessionId: sessionId
                };
                url = $.param(args)
            }
        }catch(e){
            console.log(e);
        }
        if(as_args){
            return args
        }
        return url;
    },

    get_utm_params: function(as_args=false){
        let searchParams = new URLSearchParams(window.location.search);
        let args = {};
        if(searchParams.get("utm_source")){
            args.utm_source = searchParams.get("utm_source");
        }
        if(searchParams.get("utm_medium")){
            args.utm_medium = searchParams.get("utm_medium");
        }
        if(searchParams.get("utm_campaign")){
            args.utm_campaign = searchParams.get("utm_campaign");
        }
        if(searchParams.get("utm_content")){
            args.utm_content = searchParams.get("utm_content");
        }
        if(searchParams.get("utm_term")){
            args.utm_term = searchParams.get("utm_term");
        }
        if(as_args){
            return args;
        }
        return $.param(args);

    }
});